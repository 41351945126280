export interface ObituaryDto {
  firstName: string;
  lastName: string;
  dob: string;
  dod: string;
  description: string;
  publisherFirstName: string;
  publisherLastName: string;
  middleName?: string;
  publisherRelation?: string;
  email: string;
  type: string;
  city: string;
  country: string;
  state: string;
}

export const initalState = {
  firstName: "",
  lastName: "",
  dob: "",
  dod: "",
  description: "",
  middleName: "",
  publisherFirstName: "",
  publisherLastName: "",
  publisherRelation: "",
  email: "",
  type: "CREATOR",
  city: "",
  country: "",
  state: "",
};

export const paymentInitialState = {
  id: 0,
  deceased: {
    id: 0,
    firstName: "",
    lastName: "",
    dob: "",
    dod: "",
    description: "",
    city: "",
    country: "",
    state: "",
    address: null,
    tributeImage: null,
    payment: null,
  },
  contentPriceDetails: {
    totalCharge: 0,
    originalTotalCharge: 0,
    basicPrice: 0,
    videoPrice: 0,
    imagesPrice: 0,
    noOfPhotos: 0,
    noOfVideo: 0,
    pdfPrice: 0,
    noOfPdfPages: 0,
  },
};

export const obituaryPostPaymentIntialState = {
  vimeoPath: "",
  discountAmount: "",
  isFlatDiscount: false,
  name: "",
  profile: "",
  discount: 0,
  coupon: "",
  deceased: {
    id: "",
    firstName: "",
    lastName: "",
    dob: "",
    dod: "",
    description: "",
    city: "",
    country: "",
    state: "",
    address: "",
    tributeImage: "",
  },
  contentPriceDetails: {
    totalCharge: 0,
    originalTotalCharge: 0,
    basicPrice: 0,
    videoPrice: 0,
    imagesPrice: 0,
    noOfPhotos: 0,
    noOfVideo: 0,
    pdfPrice: 0,
    noOfPdfPages: 0,
  },
  publisherRequest: {
    id: "",
    status: "",
    charge: "",
    serviceCharge: "",
    totalCharge: "",
    publisherType: "",
  },
};

export const obiturayDetailInitalState = {
  vimeoPath: "",
  deceasedPerson: {
    id: 0,
    firstName: "",
    lastName: "",
    middleName: null,
    dob: "",
    dod: "",
    description: "",
    city: "",
    country: "",
    state: "",
    address: null,
    tributeImage: "",
    payment: null,
  },
  publisherRequest: {
    id: 0,
    status: "",
    charge: 0,
    serviceCharge: null,
    totalCharge: null,
    publisherType: "",
    publisher: {
      id: 0,
      firstName: "",
      lastName: "",
      email: "",
      contactNo: null,
      type: "",
      multipleEmailAdd: null,
    },
  },
  obituaryContents: [],
};

export const addMemoriesInitialState = {
  id: 0,
  status: "",
  discountAmount: "",
  name: "",
  profile: "",
  discount: 0,
  isFlatDiscount: false,
  coupon: "",
  charge: {
    totalCharge: 0,
    originalTotalCharge: 0,
    basicPrice: 0,
    videoPrice: 0,
    imagesPrice: 0,
    noOfPhotos: 0,
    noOfVideo: 0,
    pdfPrice: 0,
    noOfPdfPages: 0,
  },
  publisher: {
    id: 0,
  },
  creator: {
    firstName: "",
    lastName: "",
  },
  serviceCharge: 0,
  totalCharge: null,
  publisherType: "CONTRIBUTOR",
};
