import { baseUrl } from "./interceptor";

export const getObituaryListPostService = (page: number) => {
  return baseUrl.post(`obituary/all`, {
    pageRequest: {
      size: 9,
      page: page,
    },
    searchCriterias: [
      {
        criteriaName: "name",
        value: "",
      },
    ],
  });
};

export const getObituarySearchPost = (value: string) => {
  return baseUrl.post(`obituary/all`, {
    pageRequest: {
      size: 9,
      page: 0,
    },
    searchCriterias: [
      {
        criteriaName: "name",
        value,
      },
    ],
  });
};

export const getObituaryDetailsService = (id: any, requestId: any) => {
  return baseUrl.get(`obituary/get?id=${id}&requestId=${requestId}`);
};

export const shareObituaryService = (id: any, shareEmails: string[]) => {
  return baseUrl.post(`obituary/share`, {
    id,
    shareEmails,
  });
};

export const addMemoriesService = (data: any) => {
  return baseUrl.post(`obituary/contribute`, data, {
    "Content-Type": "multipart/form-data",
  } as any);
};

export const reportAbuseService = (body: any) => {
  return baseUrl.patch(`deceasedperson/abuse`, body);
};

export const getApproveContent = (id: any) => {
  return baseUrl.get(`obituary/approve?id=${id}`);
};

export const getRejectContent = (id: any) => {
  return baseUrl.get(`obituary/reject?id=${id}`);
};

export const contactUsService = (body: any) => {
  return baseUrl.post(`obituary/contactus`, body);
};

export const deleteObituaryService = (id: any) => {
  return baseUrl.get(`deceasedperson/delete-obituary?id=${id}`);
};

export const editObituaryService = (body: any) => {
  return baseUrl.post(`deceasedperson/edit-obit`, body);
};

export const deleteImageService = (body: any) => {
  return baseUrl.put(`obituary/delete-images`, body);
};

export const applyCoupon = (body: any) => {
  return baseUrl.post(`payment/verify-coupon`, body);
};

export const paymentOrder = (body: any) => {
  return baseUrl.post(`payment/order`, body);
};

export const orbituaryPricing = () => {
  return baseUrl.get(`/payment/get`);
};