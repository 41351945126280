import { Box } from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Route, Routes, useLocation } from "react-router-dom";
import {
  default as ObituaryTabPanel,
  default as ObituraiesCreate,
} from "../../pages/obituary";
import { setObituaries } from "../../reducers/getObituaires";
import { obituariesPagination } from "../../utils/helpers/helper";
import { getObituaryListPostService } from "../../utils/services/service";
import { Loader } from "../../widgets/loader";
import Blogs from "../Blogs";
import EditObituary from "../obituary/edit";
import PaymentSuccess from "../paymentSuccess";

const Home = React.lazy(() => import("./home"));
const AddMemories = React.lazy(() => import("../addMemories"));
const ApproveReject = React.lazy(() => import("../approveReject"));
const ContactUs = React.lazy(() => import("../contactUs"));
const Help = React.lazy(() => import("../help"));

const ObituraiesView = React.lazy(() => import("../obituraiesView"));
const PrivacyPolicy = React.lazy(() => import("../privacyPolicy"));
const Footer = React.lazy(() => import("./footer"));
const Header = React.lazy(() => import("./header"));

function Layout() {
  const location = useLocation();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  // const [mode, setMode] = useState({
  //   edit: false,
  //   payment: false,
  //   isCoupon: false,
  // });
  useEffect(() => {
    let unmounted = false;

    const getObituaryList = async () => {
      setLoader(true);
      let response: any = await getObituaryListPostService(0);
      if (response && response.code === 200) {
        if (!unmounted) {
          const pagination = obituariesPagination(response.body.totalCount);
          dispatch(setObituaries({...response.body, pagination}));
        }
        setLoader(false);
      } else {
        setLoader(false);
      }
    };

    getObituaryList();

    return () => {
      unmounted = true;
    };
  }, [dispatch]);

  const getScrollBarTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    getScrollBarTop();
  }, [location.pathname]);

  const index = location.pathname.indexOf("/", 1);
  const obituaryPaymentFlow = [
    "/obituary-create",
    "/obituary-payment",
    "/obituary-share",
    "/addMemories-payment",
  ].includes(
    location.pathname.substring(
      0,
      index !== -1 ? index : location.pathname.length
    )
  );

  return (
    <>
      {!obituaryPaymentFlow && <Header />}

      <Box
        sx={{
          minHeight: `calc(100vh - ${
            obituaryPaymentFlow
              ? "132px"
              : ["/", "/home"].includes(location.pathname)
              ? "114px"
              : "218px"
          })`,
        }}
      >
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />

          <Route
            path="/obituraies-view/:id/:publisherRequestId"
            element={<ObituraiesView />}
          />
          <Route path="/addMemories" element={<AddMemories />} />
          <Route path="/obituary-create" element={<ObituaryTabPanel />} />
          <Route path="/obituary-preview" element={<ObituaryTabPanel />} />
          <Route path="/obituary-payment" element={<ObituaryTabPanel />} />
          <Route
            path="/addMemories-payment/:id/:obituaryId"
            element={<ObituaryTabPanel />}
          />
          <Route path="/approveRejectContent" element={<ApproveReject />} />
          <Route
            path="/delete-obituary/:obituaryId"
            element={<ApproveReject />}
          />
           <Route
            path="/edit-obituary/:id/:publisherRequestId"
            element={<EditObituary />}
          />
          <Route
            path="/payment-success/:id/:publisherRequestId/:type"
            element={<PaymentSuccess />}
          />

          <Route
            path="/obituary-share/:id/:publisherRequestId"
            element={<ObituraiesCreate />}
          />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/help" element={<Help />} />
        </Routes>
      </Box>

      <Footer />
      <Loader loader={loader} />
    </>
  );
}

export default memo(Layout);
