import { memo, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import SuccessIcon from "@mui/icons-material/CheckCircle";
import { Loader } from "../../../widgets/loader";
import { ModalComponent } from "../../../widgets/modal/modal";
import DeleteIcon from "../../../assests/images/delete.png";
import { obiturayDetailInitalState } from "../../obituary/create/type";
import {
  deleteImageService,
  getObituaryDetailsService,
} from "../../../utils/services/service";
import TextInput from "../../../widgets/textInput";
import { Box, Button, Container, Grid } from "@mui/material";
import Typography from "@mui/material/Typography/Typography";
import { makeStyles } from "@mui/styles";
import dayjs from "dayjs";
import { SetStateAction, useCallback, useMemo, useRef } from "react";
import FileUpload, {
  mutipleFileSupport,
  profileExtension,
} from "../../../components/fileUpload";
import FilePreview from "../../../components/fileUpload/file-preview";
import {
  cityList,
  countryList,
  relationShips,
  stateList,
} from "../../../utils/helpers/constants";
import { obituariesImagePreviewer } from "../../../utils/helpers/helper";
import { validateEmptyFields } from "../../../utils/helpers/helper";
import AutoComplete from "../../../widgets/autoComplete";
import DatePicker from "../../../widgets/datePicker";
import EmailInput from "../../../widgets/emailInput";
import ImageCroper from "../../../components/imageCroper";
import { baseUrl } from "../../../utils/services/interceptor";
import { ObituaryDto, initalState } from "./type";
const useStyles = makeStyles({
  container: {
    marginTop: "10px !important",
  },
  processToReviewContainer: {
    marginTop: "20px !important",
    padding: "20px 0",
    background: "linear-gradient(273.53deg, #9088C5 2.52%, #51438A 95.45%)",
    borderRadius: "16px 16px 0px 0px",
  },
  error: {
    color: "#E16F6F",
    fontSize: "0.75rem",
    lineHeight: 1.66,
    letterSpacing: "0.03333em",
    marginTop: "3px",
    marginRight: "14px",
    fontFamily: "Lato",
  },
  total: {
    color: "#fff",
    fontFamily: "Lato !important",
    fontWeight: "400 !important",
    fontSize: "20px !important",
    lineHeight: "140%",
    "@media (max-width: 768px)": {
      fontSize: "16px !important",
    },
  },

  toolTipContainer: {
    color: "#fff",
    fontFamily: "Lato",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "140%",
    padding: "16px",
  },
  processPreviewButton: {
    boxShadow: "none !important",
    color: "#232227 !important",
    backgroundColor: "#fff !important",
    fontFamily: "Lato !important",
    fontSize: "20px !important",
    "@media (max-width: 768px)": {
      fontSize: "16px !important",
      padding: "10px !important",
    },
  },
});

const EditObituary = (props: any) => {
  const [obiturayDetail, setObiturayDetails] = useState(
    obiturayDetailInitalState
  );

  const { id, publisherRequestId } = useParams();

  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [deleteImg, setDeleteImg] = useState("");
  const [successOpen, setSuccessOpen] = useState(false);
  const [dataUpdated, setDataUpdated] = useState(false);
  const [updateObituaryData, setupdateObituaryData] =
    useState<ObituaryDto>(initalState);
  const navigate = useNavigate();

  const classes = useStyles();
  const getStates = useCallback(
    (countryName: string) => {
      const stateRes = stateList?.filter(
        (state: any) =>
          state.label.toLocaleLowerCase() === countryName.toLocaleLowerCase()
      );
      setStatesList(stateRes[0]?.state || []);
    },
    [updateObituaryData.country]
  );
  const [profileImg, setProfileImg] = useState<string>();
  const [openModal, setOpenModal] = useState({ open: false, image: "" });
  const [confirmModal, setConfirmModal] = useState(false);
  const [multimediaFile, setMultimedia] = useState([]);
  const [additionalFiles, setAdditionalFiles] = useState([]);
  const [isValidate, setIsValidate] = useState(false);
  const [statesList, setStatesList] = useState<any[]>([]);
  const [cityLists, setCityLists] = useState<any[]>([]);
  const [loader, setLoader] = useState(false);
  const [dateValidation, setDateValidation] = useState("");
  const [error, setError] = useState("");

  const [progressBar, setProgressBar] = useState("");

  const removeProfile = (files: any) => {
    setProfileImg("");
  };

  const getorbituaryDetails = async () => {
    setLoader(true);
    let response: any = await getObituaryDetailsService(id, publisherRequestId);

    if (response.code === 200) {
      const obiturayDetail = response.body;
      setLoader(false);
      setupdateObituaryData({
        city: obiturayDetail.deceasedPerson.city,
        country: obiturayDetail.deceasedPerson.country,
        description: obiturayDetail.deceasedPerson.description,
        dob: obiturayDetail.deceasedPerson.dob,
        dod: obiturayDetail.deceasedPerson.dod,
        email: obiturayDetail.publisherRequest.publisher.email,
        firstName: obiturayDetail.deceasedPerson.firstName,
        lastName: obiturayDetail.deceasedPerson.lastName,
        middleName: obiturayDetail.deceasedPerson.middleName
          ? obiturayDetail.deceasedPerson.middleName
          : "",
        publisherFirstName: obiturayDetail.publisherRequest.publisher.firstName,
        publisherLastName: obiturayDetail.publisherRequest.publisher.lastName,
        // publisherRelation: obiturayDetail.publisherRequest.publisher.,
        state: obiturayDetail.deceasedPerson.state,
        type: "CREATOR",
        id: id,
      });
      setProfileImg(obiturayDetail.deceasedPerson.tributeImage);
      setMultimedia(obiturayDetail.obituaryContents);
    } else {
      setMessage(response.message);
      setLoader(false);
    }
    setLoader(false);
  };

  useEffect(() => {
    getorbituaryDetails();
    return () => {};
  }, [id, publisherRequestId]);

  const editObituary = useCallback((data: any, update?: boolean) => {
    return baseUrl.put(`/obituary/update/${publisherRequestId}`, data, {
      "Content-Type": "multipart/form-data",
      onUploadProgress: ({ progress }: any) => {
        setProgressBar((progress * 100).toFixed(0));
      },
    } as any);
  }, []);

  const updateUploadedFiles = (files: any) => {
    setAdditionalFiles(files);
  };

  // Funtion to delete IMAGE from S3 bucket
  const deleteImage = async (id: any) => {
    let img = [];
    img.push(id);
    setLoader(true);
    let response: any = await deleteImageService(img);

    if (response.code === 200) {
      img = [];
      let filteredUsers = multimediaFile.filter((file: any) => file.id !== id);
      setSuccess(true);
      setConfirmModal(false);
      setMessage(response.message);
      setDataUpdated(false);
      setSuccessOpen(true);
      setMultimedia(filteredUsers);
    } else {
      img = [];
      setSuccess(false);
      setConfirmModal(false);
      setError(response.message);
      setDataUpdated(false);
      setMessage(response);
      setSuccessOpen(true);
    }
    setLoader(false);
  };

  const dateValidations = useMemo(() => {
    const currentDate = formatDate(new Date());
    let isValidDate = true;

    const error = () => {
      setDateValidation("Please Enter Valid Date");
      isValidDate = false;
    };

    if (updateObituaryData.dob !== "" && updateObituaryData.dod !== "") {
      if (updateObituaryData.dob === updateObituaryData.dod) {
        error();
      } else if (
        updateObituaryData.dod > currentDate ||
        updateObituaryData.dob > currentDate
      ) {
        error();
      } else if (
        updateObituaryData.dob > updateObituaryData.dod ||
        updateObituaryData.dod < updateObituaryData.dob
      ) {
        error();
      } else {
        setDateValidation("");
        isValidDate = true;
      }
    }

    return isValidDate;
  }, [updateObituaryData.dob, updateObituaryData.dod]);

  const removeFile = (fileName: any) => {
    // delete files[fileName];
    // setFiles({ ...files });
    // callUpdateFilesCb({ ...files });
  };

  function formatDate(date: any) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  const ImagePreviewer = (files: any, onlyPath?: string) => {
    return Object.keys(files).map((fileName, index) => {
      let file: any = files[fileName];
      if (file.imageType == "RAW" && file.publisherType == "CREATOR") {
        const path = file.path;
        const isImage = file.type.split("/")[0];

        return onlyPath ? (
          path
        ) : (
          <Grid
            item
            xs={12}
            md={6}
            key={`${fileName}_${index}`}
            sx={{ position: "relative" }}
          >
            {obituariesImagePreviewer(isImage, path, index, index + 1)}
            <img
              src={DeleteIcon}
              height={"40px"}
              width={"40px"}
              alt={`Delete icon ${index}`}
              style={{
                cursor: "pointer",
                position: "absolute",
                right: "6px",
                top: "25px",
              }}
              onClick={(id) => {
                setConfirmModal(true);
                setDeleteImg(file.id);
              }}
            />
          </Grid>
        );
      }
    });
  };

  const ProfileImagePreviewer = (files: any, onlyPath?: string) => {
    const Img = [{ path: files, type: "IMAGE" }];

    return Object.keys(Img).map((fileName, index) => {
      let file: any = Img[index];
      const path = file.path;
      const isImage = "IMAGE";

      return onlyPath ? (
        path
      ) : (
        <Grid
          item
          xs={12}
          md={6}
          key={`${fileName}_${index}`}
          sx={{ position: "relative" }}
        >
          {obituariesImagePreviewer(isImage, path, index, index + 1)}
          {/* <img
            src={DeleteIcon}
            height={"40px"}
            width={"40px"}
            alt={`Delete icon ${index}`}
            style={{
              cursor: "pointer",
              position: "absolute",
              right: "6px",
              top: "25px",
            }}
            onClick={() => removeProfile(profileImg)}
          /> */}
        </Grid>
      );
    });
  };

  const filesPreviewer = useMemo(() => {
    return (
      <FilePreview
        filesList={additionalFiles}
        isDelete={true}
        updateFilesCb={updateUploadedFiles}
      />
    );
  }, [additionalFiles]);

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    let validateInfo: any = { ...updateObituaryData };

    delete validateInfo.middleName;
    delete validateInfo.publisherRelation;

    let totalsize = 0;

    await additionalFiles.forEach((file: any) => {
      totalsize = totalsize + file.size / Math.pow(1024, 2);
    });

    setLoader(true);
    let validateData = await validateEmptyFields(validateInfo);

    if (!validateData || !profileImg || !dateValidations) {
      handleFocus();
      setIsValidate(true);
      setLoader(false);
      return true;
    } else if (totalsize > 100) {
      setLoader(false);
      setError("Maximum upload files size should be less than 100 MB");
    } else {
      setError("");
      setLoader(true);

      const data = new FormData();

      const newmultimediaFiles = additionalFiles.map(
        (file: File) =>
          new File([file], `${+new Date()}_${file.name.replace(/ /g, "_")}`, {
            type: file.type,
          })
      );

      // if (openModal.image) {
      //   data.append("tributeImage", openModal.image);
      // } else {
      //   data.append("tributeImage", profileImg || "");
      // }
      data.append("tributeImage", profileImg || "");
      await newmultimediaFiles.forEach((file: any) => {
        data.append("files", file);
      });

      let contentData = {
        ...updateObituaryData,
      };
      data.append("content", JSON.stringify(contentData));

      setLoader(false);

      let response: any = await editObituary(data);

      if (response.code === 200) {
        setMessage(response.message);
        setSuccess(true);
        setSuccessOpen(true);
        setDataUpdated(true);
        getorbituaryDetails();
        setError("");
      } else {
        setError(response.message);
        setSuccess(false);
        setDataUpdated(false);
      }
      setLoader(false);
    }
  };

  const getCity = useCallback(
    (stateName: string) => {
      const cityRes = cityList?.filter(
        (city: any) =>
          city.label.toLocaleLowerCase() === stateName.toLocaleLowerCase()
      );
      setCityLists(cityRes[0]?.city || []);
    },
    [updateObituaryData.state]
  );

  const handleOnChange = (event: any) => {
    const { name, value } = event.target;

    setupdateObituaryData({
      ...updateObituaryData,
      [name]: value,
    } as any);
  };

  const firstNameRef: any = useRef();
  const lastNameRef: any = useRef();
  const dateOfBirthRef: any = useRef();
  const dateOfDeathRef: any = useRef();
  const countryRef: any = useRef();
  const yourFirstNameRef: any = useRef();
  const yourLastNameRef: any = useRef();
  const emailRef: any = useRef();
  const descriptionRef: any = useRef();
  const stateRef: any = useRef();
  const cityRef: any = useRef();
  const profileImgRef: any = useRef();

  const handleFocus = () => {
    if (updateObituaryData.firstName === "") {
      firstNameRef.current.focus();
    } else if (updateObituaryData.lastName === "") {
      lastNameRef.current.focus();
    } else if (updateObituaryData.dob === "") {
      dateOfBirthRef.current.focus();
    } else if (updateObituaryData.dod === "") {
      dateOfDeathRef.current.focus();
    } else if (updateObituaryData.country === "") {
      countryRef.current.focus();
    } else if (updateObituaryData.state === "") {
      stateRef.current.focus();
    } else if (updateObituaryData.city === "") {
      cityRef.current.focus();
    } else if (updateObituaryData.publisherFirstName === "") {
      yourFirstNameRef.current.focus();
    } else if (updateObituaryData.publisherLastName === "") {
      yourLastNameRef.current.focus();
    } else if (updateObituaryData.email === "") {
      emailRef.current.focus();
    } else if (updateObituaryData.description === "") {
      descriptionRef.current.focus();
    } else if (profileImg?.length === 0) {
      profileImgRef.current.focus();
    }
  };

  const updateProfile = (files: any) => {
    setOpenModal({
      ...openModal,
      open: true,
      image: files[0],
    });
  };

  const handleOnDateChange = (name: string, value: any) => {
    setupdateObituaryData({
      ...updateObituaryData,
      [name]: dayjs(value).format("YYYY-MM-DD"),
    } as any);
  };

  return (
    <>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          columnSpacing={{ md: 3 }}
          rowSpacing={{ xs: 3 }}
        >
          <Grid item xs={12} md={6}>
            <Grid
              container
              direction="row"
              alignItems="center"
              columnSpacing={{ md: 2 }}
              rowSpacing={{ xs: 2 }}
            >
              <Grid
                item
                xs={12}
                className={"main-text"}
                sx={{ textTransform: "uppercase" }}
              >
                WHO IS THIS PAGE FOR ? *
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  name={"firstName"}
                  label="First Name *"
                  value={updateObituaryData.firstName}
                  onChange={handleOnChange}
                  error={isValidate}
                  helperText={"Please Enter First Name"}
                  nameRef={firstNameRef}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  label="Middle Name"
                  name={"middleName"}
                  value={updateObituaryData.middleName}
                  onChange={handleOnChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  name={"lastName"}
                  label="Last Name *"
                  value={updateObituaryData.lastName}
                  error={isValidate}
                  helperText={"Please Enter Last Name"}
                  onChange={handleOnChange}
                  nameRef={lastNameRef}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  className={"main-text"}
                  sx={{ textTransform: "uppercase" }}
                >
                  DATE OF Birth *
                </Typography>
                <DatePicker
                  nameRef={dateOfBirthRef}
                  name={"dob"}
                  value={updateObituaryData.dob}
                  error={
                    (isValidate && updateObituaryData.dob === "") ||
                    dateValidation !== ""
                  }
                  helperText={dateValidation || "Please Enter Date Of Birth"}
                  onChange={(newValue: SetStateAction<dayjs.Dayjs | null>) => {
                    handleOnDateChange("dob", newValue);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  className={"main-text"}
                  sx={{ textTransform: "uppercase" }}
                >
                  DATE OF DEATH *
                </Typography>
                <DatePicker
                  nameRef={dateOfDeathRef}
                  name={"dod"}
                  value={updateObituaryData.dod}
                  error={
                    (isValidate && updateObituaryData.dod === "") ||
                    dateValidation !== ""
                  }
                  helperText={dateValidation || "Please Enter Date Of Death"}
                  onChange={(newValue: SetStateAction<dayjs.Dayjs | null>) => {
                    handleOnDateChange("dod", newValue);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid
              container
              direction="row"
              columnSpacing={{ md: 2 }}
              rowSpacing={{ xs: 2 }}
            >
              <Grid
                item
                xs={12}
                className={"main-text"}
                sx={{ textTransform: "uppercase" }}
              >
                Where did they LIVE IN MOST RECENTLY ? *
              </Grid>
              <Grid item xs={12}>
                <AutoComplete
                  label={"Country *"}
                  name={"country"}
                  nameRef={countryRef}
                  value={updateObituaryData.country}
                  error={isValidate}
                  helperText={"Please Select Country From List"}
                  optionsList={countryList}
                  onInputChange={(_e: any, value: string) => {
                    getStates(value);
                    setupdateObituaryData((prevState) => {
                      return {
                        ...prevState,
                        country: value,
                        state: "",
                        city: "",
                      };
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <AutoComplete
                  label={"State *"}
                  name={"state"}
                  nameRef={stateRef}
                  value={updateObituaryData.state}
                  error={isValidate}
                  helperText={"Please Select State From List"}
                  optionsList={statesList}
                  onInputChange={(e: any, value: string) => {
                    getCity(value);
                    setupdateObituaryData((prevState) => {
                      return { ...prevState, state: value, city: "" };
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <AutoComplete
                  label={"City *"}
                  name={"city"}
                  nameRef={cityRef}
                  error={isValidate}
                  helperText={"Please Select City From List"}
                  value={updateObituaryData.city}
                  optionsList={cityLists}
                  onInputChange={(_e: any, value: string) => {
                    setupdateObituaryData((prevState) => {
                      return { ...prevState, city: value };
                    });
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
            <Grid
              container
              direction="row"
              columnSpacing={{ md: 2 }}
              rowSpacing={{ xs: 2 }}
              className={classes.container}
            >
              <Grid
                item
                xs={12}
                className={"main-text"}
                sx={{ textTransform: "uppercase" }}
              >
                About you
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  name={"publisherFirstName"}
                  label="First Name *"
                  value={updateObituaryData.publisherFirstName}
                  error={isValidate}
                  helperText={"Please Enter First Name"}
                  onChange={handleOnChange}
                  nameRef={yourFirstNameRef}
                />
              </Grid>

              <Grid item xs={12}>
                <TextInput
                  name={"publisherLastName"}
                  label="Last Name *"
                  value={updateObituaryData.publisherLastName}
                  error={isValidate}
                  helperText={"Please Enter Last Name"}
                  onChange={handleOnChange}
                  nameRef={yourLastNameRef}
                />
              </Grid>

              <Grid item xs={12}>
                <EmailInput
                  name={"email"}
                  label="E-mail *"
                  value={updateObituaryData.email}
                  error={isValidate}
                  onChange={handleOnChange}
                  nameRef={emailRef}
                  disable={true}
                />
              </Grid>

              <Grid item xs={12}>
                <AutoComplete
                  name={"publisherRelation"}
                  label="You are the person’s"
                  value={updateObituaryData.publisherRelation || ""}
                  optionsList={relationShips}
                  onChange={(e: any, value: string) => {
                    setupdateObituaryData((prevState) => {
                      return { ...prevState, publisherRelation: value };
                    });
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} className={classes.container}>
            <Typography
              className={"main-text"}
              sx={{
                paddingTop: "15px",
                paddingBottom: "5px",
                textTransform: "uppercase",
              }}
            >
              Cover Photo of the Person *
            </Typography>
            <Box sx={{ position: "relative" }}>
              {profileImg?.length === 0 ? (
                <>
                  <Box
                    className="main-text"
                    sx={{
                      color: "#e16f6f!important",
                      fontSize: "12px !important",
                      paddingBottom: "8px",
                    }}
                  >
                    * Recommended resolution 200 x 200
                  </Box>
                  <FileUpload
                    accept={profileExtension}
                    filesList={openModal.image}
                    profile={true}
                    multiple={false}
                    updateFilesCb={updateProfile}
                    fileButtonRef={profileImgRef}
                    error={isValidate}
                    helperText="Please Add Cover Photo Of the Person"
                  />
                </>
              ) : (
                <>
                  {/* {openModal.image ? (
                    <FilePreview
                      filesList={[openModal.image]}
                      profile={true}
                      isDelete={true}
                      updateFilesCb={removeProfile}
                    />
                  ) : ( */}
                   { ProfileImagePreviewer(profileImg)}
                  {/* )} */}
                </>
              )}
            </Box>
            <Grid
              item
              xs={12}
              md={6}
              sx={{ position: "relative", marginTop: "23px" }}
            >
              <FileUpload
                accept={mutipleFileSupport}
                multiple={false}
                filesList={additionalFiles}
                updateFilesCb={updateUploadedFiles}
              />
            </Grid>
          </Grid>

          <Grid
            container
            sx={{ display: "flex", flexDirection: "row" }}
            columnSpacing={2}
            rowSpacing={2}
            my={2}
          >
            <>
              {ImagePreviewer(multimediaFile)}
              {filesPreviewer}
            </>
          </Grid>
          <Grid item xs={12}>
            <Typography
              className={"main-text"}
              sx={{ textTransform: "uppercase" }}
              pb={1}
            >
              Write an obituary *
            </Typography>
            <TextInput
              rows={6}
              name="description"
              value={updateObituaryData.description}
              multiline={true}
              maxLength={2000}
              error={isValidate}
              helperText={"Please Enter Description"}
              onChange={handleOnChange}
              nameRef={descriptionRef}
            />
          </Grid>
        </Grid>

        {error && (
          <Grid item xs={12} className="main-text Mui-error">
            {error}
          </Grid>
        )}
      </Container>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
        className={classes.processToReviewContainer}
      >
        <Grid item xs={6} sm={4} md={3}>
          <Button
            fullWidth
            variant="contained"
            onClick={handleSubmit}
            sx={{ textTransform: "capitalize" }}
            className={classes.processPreviewButton}
          >
            Update Orbituary
          </Button>
        </Grid>
      </Grid>

      {/* Profile Image modal */}
      <ModalComponent
        openModal={openModal?.open}
        modalWidth={300}
        closeModal={() => {
          setOpenModal({
            ...openModal,
            open: false,
          });
        }}
        closeIcon
        content={
          <ImageCroper
            image={openModal?.image}
            setCropImage={(cropImage: any) => {
              setOpenModal({
                ...openModal,
                open: false,
              });
              setProfileImg(cropImage);
            }}
          />
        }
      />

      {/* Modal for SuccessfulUpdate */}
      <ModalComponent
        openModal={successOpen}
        closeModal={() => {
          dataUpdated ? navigate("/home") : setSuccessOpen(false);
          setMessage("");
        }}
        closeIcon
        content={
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            padding="10px"
            rowSpacing={{ xs: 1, md: 2 }}
          >
            {success ? (
              <SuccessIcon
                sx={{
                  fontSize: "100px",
                  color: "green",
                  "@media (max-width: 400px)": {
                    fontSize: "60px",
                  },
                }}
              />
            ) : (
              ""
            )}
            <Grid item xs={12} md={12} className="h2">
              {message}
            </Grid>
            {dataUpdated ? (
              <Grid item xs={12} sm={10}>
                <Button
                  fullWidth
                  variant="contained"
                  className="modal-button"
                  onClick={() => {
                    navigate("/home");
                  }}
                >
                  Go to the Obituary page
                </Button>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        }
      />
      {/* Modal for Delete image Confirmation */}
      <ModalComponent
        openModal={confirmModal}
        closeModal={() => {
          setConfirmModal(false);
          setMessage("");
        }}
        closeIcon
        content={
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            spacing={3}
          >
            <Grid item xs={12} md={12} className="h2">
              Are you sure you want to permanently delete this file?
            </Grid>

            <Grid item xs={12} sm={10} sx={{ display: "flex" }}>
              <Button
                fullWidth
                variant="contained"
                className="modal-button"
                sx={{ marginRight: "10px" }}
                onClick={() => deleteImage(deleteImg)}
              >
                Delete
              </Button>
              <Button
                fullWidth
                variant="contained"
                className="modal-button"
                onClick={() => {
                  setConfirmModal(false);
                  setMessage("");
                }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        }
      />

      <Loader loader={loader} message={progressBar} size="3.5rem" />
    </>
  );
};

export default memo(EditObituary);
