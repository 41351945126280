import SuccessIcon from "@mui/icons-material/CheckCircle";
import { Button, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Create from "../../assests/images/create.png";
import { ModalComponent } from "../../widgets/modal/modal";

const PaymentSuccess = () => {
  const { id, publisherRequestId, type } = useParams();
  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState(false);
  const [addMemoriesModal, setAddMemoriesModal] = useState(false);

  useEffect(() => {
    if (sessionStorage.getItem("paymentSession")) {
      if (id && publisherRequestId && type === "create") {
        setOpenModal(true);
        sessionStorage.removeItem("paymentSession");
      } else {
        setAddMemoriesModal(true);
        sessionStorage.removeItem("paymentSession");
      }
    } else {
      navigate("/obituary-create");
    }
  }, [id, publisherRequestId, type]);

  return (
    <>
      <ModalComponent
        openModal={openModal}
        closeModal={() => {
          setOpenModal(false);
        }}
        content={
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            rowSpacing={{ xs: 1, md: 2 }}
          >
            <SuccessIcon
              sx={{
                fontSize: "100px",
                color: "green",
                "@media (max-width: 400px)": {
                  fontSize: "60px",
                },
              }}
            />
            <Grid item xs={12} md={12} className="h2">
              Payment Successful!
            </Grid>
            <Grid item xs={12} md={12} className="main-text">
              Now you can watch the video and share obituary with others.
            </Grid>

            <Grid item xs={12} md={12}>
              <Button
                fullWidth
                variant="contained"
                sx={{ textTransform: "capitalize" }}
                className="modal-button"
                onClick={() => {
                  setOpenModal(false);
                  navigate(`/obituary-share/${id}/${publisherRequestId}`);
                }}
              >
                Proceed
              </Button>
            </Grid>
          </Grid>
        }
      />

      <ModalComponent
        openModal={addMemoriesModal}
        content={
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            rowSpacing={{ xs: 1, md: 2 }}
          >
            <Grid item xs={12} md={12}>
              <img
                alt="create"
                src={Create}
                style={{
                  width: "180px",
                  height: "120px",
                  transform: "scale(1.1)",
                }}
              />
            </Grid>
            <Grid item xs={12} md={12} className="h2">
              Your additions are sent!
            </Grid>
            <Grid item xs={12} md={12} className="main-text">
              {type} will receive it via email. It will be added to this
              obituary after {type} confirms it.
            </Grid>

            <Grid item xs={12} md={12}>
              <Button
                fullWidth
                variant="contained"
                sx={{ textTransform: "capitalize" }}
                className="modal-button"
                onClick={() => {
                  navigate(`/obituraies-view/${id}/${publisherRequestId}`);
                  setAddMemoriesModal(false);
                }}
              >
                See current version of obituary
              </Button>
            </Grid>
          </Grid>
        }
      />
    </>
  );
};

export default PaymentSuccess;
