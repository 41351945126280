import { Avatar, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import { memo } from "react";
import Vector1 from "../../assests/images/Vector1.png";
import Vector2 from "../../assests/images/Vector2.png";
import { formatDate } from "../../utils/helpers/helper";
const useStyles = makeStyles({
  container: {
    backgroundColor: "#F2F2F2",
    borderRadius: "4px",
  },
  tributeImage: {
    width: "160px !important",
    height: "160px !important",
    borderRadius: "100%",
    overflow: "hidden",
    position: "relative",
    left: "50%",
    transform: "translate(-50%, -30%)",
    "@media (max-width: 768px)": {
      width: "140px !important",
      height: "140px !important",
    },
  },
  info: {
    marginTop: "-30px !important",
    paddingBottom: "30px !important",
    textAlign: "center",
    "@media (max-width: 425px)": {
      paddingRight: "10px !important",
      paddingLeft: "10px !important",
    },
  },
});

const SideImage = (props: any) => (
  <Grid
    item
    sm={props.sm}
    md={props.md}
    sx={{
      backgroundImage: `url(${props.image})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: props.position,
      width: "100%",
      backgroundSize: "contain",
    }}
  />
);

interface ProfileViewProps {
  profileImage?: any;
  obiturayDetail?: any;
}
const TributeImageView = (props: ProfileViewProps) => {
  const {
    tributeImage,
    firstName,
    middleName,
    lastName,
    dob,
    dod,
    city,
    state,
  } = props.obiturayDetail;
  const { profileImage } = props;

  const classes = useStyles();
  const name = `${firstName} ${middleName && middleName} ${lastName}`;

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      className={classes.container}
    >
      <SideImage sm={3} md={3} position={"left"} image={Vector1} />
      <Grid item xs={12} sm={6} md={6}>
        <Avatar
          alt={"tributeImage"}
          src={tributeImage || profileImage}
          sx={{
            height: "160px !important",
            width: "160px !important",
            loading: "lazy",
          }}
          className={classes.tributeImage}
        />
        {firstName && (
          <Box className={classes.info}>
            <Box
              className="h2"
              sx={{
                textTransform: "capitalize !important",
              }}
            >
              {name}
            </Box>

            <Box className="main-text" pt={2}>
              {`${formatDate(dob)} - ${formatDate(dod)}`}
            </Box>

            <Box
              className="main-text"
              pt={1}
              sx={{
                textTransform: "capitalize !important",
              }}
            >
              {`${name} was born in ${city}, ${state} and peacefully passed away.`}
            </Box>
          </Box>
        )}
      </Grid>

      <SideImage sm={3} md={3} position={"right"} image={Vector2} />
    </Grid>
  );
};

export default memo(TributeImageView);
